<template>
  <div v-if="user" class="flex-grow-1">
    <v-fade-transition v-if="!hasProfile">
      <v-overlay absolute color="white" opacity="1">
        <v-sheet color="transparent" class="text-center">
          <h4 class="text-h3 font-weight-black">
            Welcome, {{ user.fullName }}!
          </h4>
          <p class="primary--text subtitle-1 mb-7">
            Update your profile so employer can invite you for a job.
          </p>
          <v-btn color="accent gradient" :to="{ name: 'AccountSettings' }"
            >UPDATE PROFILE</v-btn
          >
        </v-sheet>
      </v-overlay>
    </v-fade-transition>

    <v-row v-if="hasProfile">
      <v-col
        cols="12 profile-cover dark justify-center align-center mb-6"
        :style="`background: url(${ backgroundCover ? backgroundCover : 'http://placeimg.com/1440/343/tech'}) 0% 0% / 100% no-repeat;`"
      >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-card max-width="300px" class="shadow-lg rounded-lg">
          <v-card-text class="pa-3">
            <UserPhoto
              v-if="profile"
              :id="user.userid"
              photoSize="small"
              size="100%"
              tile
            />
          </v-card-text>

          <preloader v-if="!profile && hasProfile" />
          <v-card-title v-if="profile" class="d-flex flex-column pa-4 pt-7">
            <div class="d-flex gap-7 flex-row align-self-start">
              <a
                v-if="profile.twitter"
                class="text-decoration-none"
                target="_blank"
                :href="'//' + profile.twitter"
              >
                <v-btn color="primary" class="primary-link" dark fab small>
                  <v-icon dark>mdi-twitter</v-icon>
                </v-btn>
              </a>
              <a
                v-if="profile.facebook"
                class="text-decoration-none"
                target="_blank"
                :href="'//' + profile.facebook"
              >
                <v-btn color="primary" class="primary-link" fab dark small>
                  <v-avatar size="24">
                    <v-img src="/facebook.svg" height="24"></v-img>
                  </v-avatar>
                </v-btn>
              </a>
              <a
                v-if="profile.instagram"
                class="text-decoration-none"
                target="_blank"
                :href="'//' + profile.instagram"
              >
                <v-btn color="primary" class="primary-link" fab dark small>
                  <v-icon dark>mdi-instagram</v-icon>
                </v-btn>
              </a>
            </div>

            <div class="d-flex profile-social-divider">
              <v-divider class="my-5"></v-divider>
            </div>
          </v-card-title>

          <preloader v-if="!profile && hasProfile" />
          <v-card-subtitle v-if="profile" class="d-flex flex-column">
            <div class="mb-4">
              <a
                class="text-decoration-underline font-weight-medium primary--text primary-link"
                v-if="user.email"
                :href="`mailto:${user.email}`"
              >
                <v-icon color="primary" size="20" class="mr-2">
                  mdi-email-outline
                </v-icon>
                <span>
                  {{ user.email }}
                </span>
              </a>
            </div>
            <div class="mb-4">
              <a
                :href="`tel:${profile.phone}`"
                class="text-decoration-underline font-weight-medium primary--text primary-link"
              >
                <v-icon color="primary" size="20" class="mr-2">
                  mdi-phone-dial-outline
                </v-icon>
                <span>
                  {{ profile.phone }}
                </span>
              </a>
            </div>
            <div class="mb-4">
              <a
                :href="'//' + profile.website"
                class="text-decoration-none grey--text"
                target="_blank"
                v-if="profile.website"
              >
                <v-icon class="mr-2"> mdi-web </v-icon>
                {{ profile.website }}
              </a>
            </div>
          </v-card-subtitle>
        </v-card>

        <v-btn :to="{ name: 'AccountSettings' }" class="mt-5" color="accent gradient" block>
          Edit Profile
        </v-btn>

        <v-btn :to="{ name: 'Availability' }" class="mt-5" color="accent gradient" block>
          Edit Availability
        </v-btn>
      </v-col>

      <preloader v-if="!profile && hasProfile" />

      <v-col v-if="profile" class="pl-10 profile-details" cols="9">
        <div class="d-flex flex-column">
          <div>
            <span class="white--text font-weight-bold">
              <v-icon class="mr-0 mb-1 white--text" left size="25"
                >mdi-map-marker-outline</v-icon
              >
              {{ profile.location }}
            </span>
          </div>
          <div class="mt-3">
            <span
              style="font-size: 42px"
              class="white--text font-weight-bold text-capitalize"
              >{{ user.fullName }}</span
            >
          </div>
          <!-- skills section -->
          <div class="d-flex flex-row justify-space-between mt-4">
            <div class="d-flex align-center mb-10 profile-skills">
              <div class="d-flex gap-7">
                <!-- Profile Skillset -->
                <v-chip
                  v-for="(skill, index) in profile.skillset"
                  :key="index"
                  color="rgba(255,255,255,0.3)"
                  rounded
                  dark
                >
                  {{ skill }}
                </v-chip>
              </div>
            </div>
          </div>
          <!-- video section -->
          <div v-if="profile.videoPresentation">
            <v-card class="shadow-lg d-flex pa-1 rounded-lg" max-width="480">
              <v-card-text class="pa-2">
                <VideoEmbed
                  css="embed-responsive-21by9 video-presentation"
                  :src="profile.videoPresentation"
                ></VideoEmbed>
              </v-card-text>
            </v-card>
          </div>
          <!-- about me section -->
          <div class="d-flex flex-column mt-7">
            <span class="text-h4 font-weight-black">About me:</span>
            <div class="body-1 font-weight-medium mt-4">
              {{ profile.aboutMe }}

              <div class="d-flex justify-space-between mt-7" v-if="profile.teachingLvl">
                <div>
                  <p class="text-h4 black--text font-weight-black">Teaching Level:</p>
                  <v-icon>mdi-bulletin-board</v-icon> Year: {{ profile.teachingLvl }}
                </div>
                <div v-if="profile.schoolWorkedAt">
                  <p class="text-h4 black--text font-weight-black">Schools Worked At:</p>
                  <v-list-item
                    v-for="(school, index) in profile.schoolWorkedAt"
                    :key="index"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="text-md-body-1 font-weight-bold">
                          <v-icon>mdi-map-marker-outline</v-icon>
                          <span class="font-weight-medium">
                            {{ school }}
                          </span>
                        </span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </div>
              <v-divider class="my-9"></v-divider>
            </div>
          </div>

          <!-- Registration / Key Credentials -->
          <div class="mt-3" v-if="attachments.length">
            <span class="text-h4 primay--text  font-weight-black">Registration / Key Credentials: </span>
            <div class="mt-9">
              <!-- experience section -->
              <v-card
              class="shadow-lg mb-7"
              flat
              >
                <v-card-title primary-title>
                  <span class="text-h6 font-weight-black text-capitalize">
                    <p><span v-if="profile.teacherRegistration">Teacher Registration </span><span v-if="profile.workingWithChildrenCheck">| Working With Children Check </span><span v-if="profile.resume">| Resume</span></p>
                  </span>
                </v-card-title>
                <v-card-text>
                  <v-col class="4">
                   <v-card
                    v-for="attachment in attachments"
                    :key="attachment.id"
                    class="mb-2"
                    outlined

                  >
                    <v-card-text class="d-flex align-center pa-2">
                      <span>
                        <v-icon :color="fileIcon(attachment.type, true)" left>{{ fileIcon(attachment.type) }}</v-icon> 
                        <span class="caption info--text">{{ attachment.size | fileSize }} ({{ attachment.type }})</span>
                      </span>
                      <v-spacer></v-spacer>
                      <v-btn @click="$downloadFile('attachments', attachment.file)" class="mr-2" small icon>
                        <v-icon small>mdi-download</v-icon>
                      </v-btn>
                    </v-card-text>
                  </v-card>
                  </v-col>
                </v-card-text>
              </v-card>

              <v-divider class="my-9"></v-divider>
            </div>
          </div>

          <!-- experience section -->
          <div class="mt-3" v-if="experience[0] && experience[0].schoolName != '' && experience[0].yearStart != '' && experience[0].yearEnd != ''">
            <span class="text-h4 primay--text  font-weight-black">Recent Teaching Experience:</span>
            <div class="mt-9">
              <!-- experience section -->
              <v-card
              v-for="(value, index) in experience"
              :key="index"
              class="shadow-lg mb-7"
              flat
              >
                <v-card-title primary-title>
                  <span class="text-h6 font-weight-black text-capitalize">
                    {{ value.schoolName }} ({{ value.dateRange[0] }} - {{ value.dateRange[1]? value.dateRange[1]: 'Present' }})
                  </span>
                </v-card-title>
                <v-card-text>
                  <v-col cols="6">
                    <div class="d-flex justify-space-between">
                      <div>
                        <v-icon>mdi-billboard</v-icon> Position: {{ value.position }}
                      </div>
                      <div>
                        <v-icon>mdi-map-marker-outline</v-icon> City: {{ value.region }}
                      </div>
                    </div>
                  </v-col>
                </v-card-text>
              </v-card>

              <v-divider class="my-9"></v-divider>
            </div>
          </div>

          <!-- supervise section -->
          <div class="mt-3" v-if="profile.ableToSupervise">
            <span class="text-h4 primay--text  font-weight-black">Able To Supervise:</span>
            <div class="mt-9">
              <!-- supervise section -->
              <v-card
              class="shadow-lg mb-7"
              flat
              >
                <v-card-text>
                  <v-col cols="12">
                    <v-list-item
                      v-for="(value, index) in profile.ableToSupervise"
                      :key="index"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          <span class="text-md-body-1 font-weight-bold">
                            <v-icon>mdi-license</v-icon>
                            <span class="font-weight-medium">
                              {{ value }}
                            </span>
                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-card-text>
              </v-card>

              <v-divider class="my-9"></v-divider>
            </div>
          </div>

          <!-- education section -->
          <div class="mt-3 education-section">
            <span class="text-h4 font-weight-black">Education:</span>
            <div class="mt-9">
              <!-- Education section -->
              <v-expansion-panels
                v-for="(value, index) in education"
                :key="index"
                class="profile-accordion mb-7"
                flat
              >
                <v-expansion-panel class="shadow-lg">
                  <v-expansion-panel-header>
                    <div class="d-flex flex-column">
                      <span class="text-h6 font-weight-black text-capitalize"
                        >{{ value.schoolName }} ({{ value.fieldOfStudy }})</span
                      >
                      <span class="mt-2 grey--text font-weight-medium"
                        >{{ value.yearStart }} - {{ value.yearEnd }}</span
                      >
                    </div>
                    <template v-slot:actions>
                      <v-icon color="icon-chev" fab solo
                        >mdi-chevron-down</v-icon
                      >
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-divider class="my-5"></v-divider>
                    <p class="body-1 font-weight-medium">
                      {{ value.introduction }}
                    </p>
                    <div v-if="value.achievements">
                      <v-list-item
                        v-for="(achievement, index) in value.achievements"
                        :key="index"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <span class="text-md-body-1 font-weight-bold">
                              <v-icon> mdi-circle </v-icon>
                              <span class="font-weight-medium">
                                {{ achievement }}
                              </span>
                            </span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <!-- End Education section -->
            </div>
          </div>

          
          <!-- referees section -->
          <div v-if="referees[0] && referees[0].name != '' && referees[0].position != '' && referees[0].organisation != ''" class="mt-3 education-section">
            <span class="text-h4 primay--text  font-weight-black">Professional Referees:</span>
            <div class="mt-9">
              <!-- referees section -->
              <v-expansion-panels
                v-for="(value, index) in referees"
                :key="index"
                class="profile-accordion mb-7"
                flat
              >
                <v-expansion-panel class="shadow-lg">
                  <v-expansion-panel-header>
                    <div class="d-flex flex-column">
                      <span class="text-h6 font-weight-black text-capitalize">
                        {{ value.name }}
                      </span>
                    </div>
                    <template v-slot:actions>
                      <v-icon color="icon-chev" fab solo
                        >mdi-chevron-down</v-icon
                      >
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-divider class="my-5"></v-divider>
                    <v-row>
                      <v-col cols="6">
                        <div>
                          <div class="pa-1">
                            <v-icon>mdi-billboard</v-icon> Position: {{ value.position }}
                          </div>
                          <div class="pa-1">
                            <v-icon>mdi-account-group-outline</v-icon> Organisation: {{ value.organisation }}
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div>
                          <div class="pa-1">
                            <v-icon>mdi-phone-outline</v-icon> Phone:
                            <a
                              class="text-decoration-none font-weight-medium"
                              :href="`tel:${value.phone}`"
                            >
                              <v-hover
                              v-slot="{ hover }"
                              >
                                <span 
                                :class="{ 'on-hover text-decoration-underline': hover }"
                                >
                                  {{ value.phone }}
                                </span>
                              </v-hover>
                            </a>
                          </div>
                          <div class="pa-1">
                            <v-icon>mdi-email-outline</v-icon> Email: 
                            <a
                              class="text-decoration-none font-weight-medium"
                              :href="`mailto:${value.email}`"
                            >
                              <v-hover
                              v-slot="{ hover }"
                              >
                                <span 
                                :class="{ 'on-hover text-decoration-underline': hover }"
                                >
                                  {{ value.email }}
                                </span>
                              </v-hover>
                            </a>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <!-- End referees section -->
            </div>
          </div>


        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"

export default {
  name: "Profile",

  metaInfo: {
    title: "Profile",
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      data: [],
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      profile: (state) => state.profile.profile,
      attachments: (state) => state.profile.attachments,
      backgroundCover: (state) => state.profile.backgroundCover,
      hasProfile: (state) => state.profile.hasProfile,
      experience: (state) => state.profile.experience,
      referees: state => state.profile.referees,
      profileStatus: (state) => state.profile.status,
      education: (state) => state.profile.education,
    }),
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions("profile", ["getProfile", "getBackgroundPhotoUrl"]),

    fileIcon(type, color = false) {
       let icons = [
        {
          icon: 'mdi-file-image-outline',
          color: 'green accent-4',
          types: [
            'image/jpeg',
            'image/png'
          ]
        },
        {
          icon: 'mdi-file-pdf-outline',
          color: 'red accent-3',
          types: [
            'application/pdf',
          ]
        },
        {
          icon: 'mdi-file-code-outline',
          color: 'amber darken-2',
          types: [
            'image/svg+xml',
          ]
        },
        {
          icon: 'mdi-zip-box-outline',
          color: 'blue-grey darken-1',
          types: [
            'application/x-zip-compressed',
          ]
        },
      ]

      let icon = icons.find(i => {
        return i.types.includes(type)
      })

      if (color) return icon ? icon.color : 'blue accent-3'
      else return icon ? icon.icon : 'mdi-file-document-outline'
    },
    
  },

  created() {
    if (!this.profileStatus.firstLoad) {
      this.getProfile()
      this.getBackgroundPhotoUrl()
    }
    if (this.user && this.user.role == "employer") {
      this.getProfile()
      this.getBackgroundPhotoUrl()
    }
  },
}
</script>

<style scoped lang="scss">
.profile-cover:before {
  background-color: rgba(0, 0, 0, 0.6);
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
}

.profile-cover {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  height: 343px;
  width: 100%;
}

.profile-social-divider {
  width: 100%;
}

.profile-details {
  z-index: 4;
}

.profile-skills {
  width: 650px;
}

.profile-accordion .accordion-header .v-expansion-panel-header__icon {
  will-change: box-shadow !important;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1) !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  border-radius: 100%;
  padding: 4px;
}

.profile-accordion .icon-chev--text {
  will-change: box-shadow !important;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1) !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  border-radius: 100%;
  padding: 4px;
}

.video-presentation {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
// .embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
</style>